body {
    font-family: 'lato', sans-serif;
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 15%;
    max-width: 17%;
    z-index: 7;
}

.responsive-table li {
    border-radius: 4px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.responsive-table .table-header {
    background-color: #7a8a8b;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.responsive-table .table-row {
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
    font-size: 14px;
}

.responsive-table .col-2 {
    flex-basis: 1/3*100%;
    max-width: 33%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.responsive-table .col-3 {
    flex-basis: 1/3*100%;
}

.responsive-table .col-4 {
    flex-basis: 1/3*100%;
}

/* @media all and (max-width: 767px) {
    .responsive-table .table-header {
        display: none;
   }
    .responsive-table li {
        display: block;
   }
    .responsive-table .col {
        flex-basis: 100%;
   }
    .responsive-table .col {
        display: flex;
        padding: 10px 0;
   }
    .responsive-table .col:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
   }
} */