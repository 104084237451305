.progress {
    position: relative;
    background-color: blue;
    width: 0%;
    height: 10px;
    animation: progres 2.7s linear
}

@keyframes progres {
    0% {
        width: 100%;
    }

    100% {
        width: 0px;
    }
}