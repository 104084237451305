* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100vh;
    font-family: 'Poppins';
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}